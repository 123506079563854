<!-- 

FYI RENAMED test-model= TO test-model= FOR TESTING LAYOUT 

-->

<template>
  <main class="site--body">

    <!-- Dashboard -->
    <div class="admin dashboard" v-if="user && user.is_admin == 1">
      <div class="container">
        <div class="row">
          <div class="col">

            <!-- Page Title -->
            <h1>Analytics</h1>

            <!-- Dashboard Components -->
            <div class="row mb-5">

              <!-- Column -->
              <div class="col-12">
                <h2>Mentor/Mentee Relationships</h2>
                <div class="text-content feedback text-success bold-weight mb-2">{{ relationshipCount }} relationship(s) in database</div>

                <!-- Mentor Results -->
                <div class="search-results component">
                  <h2>Mentors</h2>
                    <div class="text-content feedback text-success bold-weight mb-2">{{ mentors.length }} mentor(s) in database</div>
                  <AnalyticResult
                    v-for="user in mentors"
                    :key="user.id"
                    :user="user"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>   
</template>

<script>
//import { useFind } from 'feathers-vuex'
//import { computed } from '@vue/composition-api'
import AnalyticResult from '../components/AnalyticResult.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SearchView',
  components: {
    AnalyticResult
  },
  data() {
    return {
      relationshipCount: 0,
      mentors: [],
      mentees: []
    }
  },
  computed: {
    ...mapGetters('users', { findResultsInStore: 'find' }),
    mentorSearch () {
      var query = {
        $sort: { name: 1 },
        $limit: 2000,
        is_mentor: 1
      };

      return { query: query};
    },
    menteeSearch () {
      var query = {
        $sort: { name: 1 },
        $limit: 2000,
        is_mentor: 0
      };

      return { query: query};
    },
    user () {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    ...mapActions('users', { findResults: "find" })
  },
  created () {
    this.findResults(this.mentorSearch).then((mentors) =>{
      this.mentors = mentors["data"];
    });
    this.findResults(this.menteeSearch).then((mentees) =>{
      mentees["data"].forEach(mentee => {
        if(JSON.parse(mentee.mentors)) {
          this.relationshipCount = this.relationshipCount + JSON.parse(mentee.mentors).length;
        }
      });
      this.mentees = mentees["data"];
    });
  }
}
</script>

<style>
/*Mentor Search*/

@media (min-width: 1200px) {
  .site--body .mentor-search .age-label {
    padding: 0;
  }
}

.site--body .search-results .faculty-name {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.site--body .search-results .title {
  font-size: 1.25rem;
  font-weight: 700;
}

.site--body .search-results .description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .site--body .search-results .right-column {
    padding-left: 2rem;
    border-left: 1px solid #C6C9CB;
  }
}
</style>
