<!-- 

FYI RENAMED test-model= TO test-model= FOR TESTING LAYOUT 

-->

<template>
<main class="site--body">

    <!-- Result -->
  <div class="result content-wrapper">
    <div class="row">
      <!-- Left Column -->
      <div class="left-column col-lg-6">
        <div class="row">
          <div class="col-12">
            <div class="faculty-name">{{ user.name }}</div>
            <div v-if="!user.completed_training" class="pending">
              <div class="training-alert text-danger heavy-weight pb-2">*AVAILABLE SOON FOR MENTORSHIP</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="title">Age</div>
            <div class="description">{{ user.age }}</div>
            <div class="title">Race/Ethnicity</div>
            <div class="description">{{ user.race }}</div>
            <div class="title">Gender Identity</div> <div class="description">{{ user.gender }}</div>
          </div>
          <div class="col-lg-6">
            <div class="title">Specialty</div>
            <div class="description">{{ specialty }}</div>
            <div class="title">Training Location</div>
            <div class="description">{{ user.education_postgrad }}</div>
          </div>
        </div>
      </div>
      <!-- Right Column -->
      <div class="right-column col-lg-6">
        <div class="title">Academic Interests</div>
        <p class="description">{{ user.interests_academic }}</p>
        <div class="title">Outside Interests</div>
        <p class="description">{{ user.interests_outside }}</p>
        <div class="title">Areas of Mentorship</div>
        <p class="description">{{ areaPreference }}</p>
        <!-- Mentor Statement and Mentor Email -->
        <div class="mentor-statement">
          <div class="title">I am a mentor because ...</div>
          <p class="description">{{ user.mentor_statement }}</p>
        </div>
        <div class="mentor-email">
          <div class="title">Preferred Email Address</div>
          <p class="description"><a :href="`mailto:${user.preferred_email}`">{{ user.preferred_email }}</a></p>
        </div>
      </div>
    </div>
</div>
</main>   
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    areaPreference() {
      const preference = JSON.parse(this.user.mentorship_area_preference);
      if (preference) {
        return preference.join(', ');
      }
      return null;
    },
    specialty() {
      const specialty = JSON.parse(this.user.specialty);
      if (specialty) {
        return specialty.join(', ');
      }
      return null;
    }
  }
}
</script>

<style>
/*Mentor Search*/
.site--body .search-results .faculty-name {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.site--body .search-results .title {
  font-size: 1.25rem;
  font-weight: 700;
}

.site--body .search-results .description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.site--body .search-results .training-alert {
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .site--body .search-results .right-column {
    padding-left: 2rem;
    border-left: 1px solid #C6C9CB;
  }
}
</style>
