<template>
  <main class="site--body">

    <!-- Dashboard -->
    <div class="Mentor dashboard">
      <div class="container">
        <div class="row">
          
          <!-- Select Your Role -->
          <div class="px-4 py-5 mb-5 text-center">
            <div class="col-lg-10 mx-auto">
              <h1 class="text-center">Welcome to Dell Med Connect</h1>
              <p class="mx-auto mb-5 w-75">A mentorship program for medical students and residents from backgrounds underrepresented in medicine.</p>
              <div class="d-grid mb-5 gap-3 d-sm-flex justify-content-md-center">
                <button type="button" class="btn ut-button btn-lg px-4" @click="setMentor(0)">If you are a <strong>mentee</strong>, click here. </button>
                <button type="button" class="btn ut-button-secondary btn-lg px-4 gap-3" @click="setMentor(1)">If you are a <strong>mentor</strong>, click here.</button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import moment from 'moment'

export default {
  name: 'RoleItem',
  computed: {
    user () {
      var user = this.$store.state.auth.user;
      // Temp fix for dob format
      if(user && user.dob) {
        user.dob = "" +moment.utc(user.dob).format('yyyy-MM-DD');
      }
      return user;
    }
  },
  methods: {
    setMentor(isMentor) {
      this.user.is_mentor = isMentor;
      this.user.first_login = 0;
      this.user.save();
      this.$router.push('/');
    }
  }
}
</script>
