import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/Home'
import LoginView from '../views/Login'
import SearchView from '../views/Search'
import AnalyticsView from '../views/Analytics'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { requiresAuth: true }
    },
    {
      path: '/search',
      name: 'search',
      component: SearchView,
      meta: { requiresAuth: true }
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: AnalyticsView,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { requiresAuth: false },
      beforeEnter: async(to, from,next) => {
        if (process.env.NODE_ENV === 'production') {
          window.location.href = "https://api.connect.dellmed.utexas.edu/saml";
        }
        next()
      }
    }
  ]
})
