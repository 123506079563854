<template>

  <!-- Body -->
  <main class="site--body">

    <!-- Dashboard -->
    <div class="mentor dashboard">
      <div class="container">
        <div class="row">
          <div class="col">
            
            <!-- Page Title -->
            <h1>Mentor Dashboard</h1>
          
            <!-- Welcome Message for Mentors -->
            <WelcomeMessage :user="user"/> 
            
            <!-- Dashboard Components -->
            <div class="row mb-5">

              <!-- LEFT COLUMN -->
              <div class="col-lg-6">

                <!-- Mentor Profile -->
                <MentorProfile/>

              </div>

              <!-- RIGHT COLUMN -->
              <div class="col-lg-6">
                
                <!-- Current Mentees -->
                <CurrentMentorship/>

                <!-- Mentorship Resources -->
                <MentorshipResources :user="user"/>
                
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </main>    
</template>

<script>
import WelcomeMessage from './WelcomeMessage.vue'
import CurrentMentorship from './CurrentMentorship.vue'
import MentorshipResources from './MentorshipResources.vue'
import MentorProfile from './MentorProfile.vue'

export default {
  name: 'MentorItem',
  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },
  components: {
    WelcomeMessage,
    CurrentMentorship,
    MentorshipResources,
    MentorProfile
  }
}
</script>

<style>

</style>
