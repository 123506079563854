<template>
  <footer class="site--footer">
    <div class="copyright-info">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-start py-4" @click.prevent="toggleMentor">
            <a class="text-link small-text bold-weight" href="#" target="_blank">Choose the wrong role by accident? Click here.</a>
          </div>
          <div class="col-lg-6 text-end py-4">
            <a class="text-link small-text bold-weight" href="https://utexas.edu" target="_blank">&copy; 2022 The University of Texas at Austin</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FooterItem',
  computed: {
    user () {
      var user = this.$store.state.auth.user;
      // Temp fix for dob format
      if(user && user.dob) {
        user.dob = "" +moment.utc(user.dob).format('yyyy-MM-DD');
      }
      return user;
    }
  },
  methods: {
    toggleMentor() {
      this.user.first_login = 1;
      this.user.save();
      this.$router.push('/');
    }
  }
}
</script>

<style>
/*Footer*/

.site--footer .copyright-info a {
  color: #ffffff;
  text-decoration: none;
}

.site--footer .copyright-info a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.site--footer .copyright-info {
  background-color: #252D33;
  border-top: 1.25rem solid #454C51;
}
</style>
