<template>
  <div class="manage-mentorship component">
    <h2>Add or Remove Mentors</h2>
    <div class="content-wrapper">
      <div class="content-block">
        <div class="text-content pb-3">If you have established a formal mentor relationship please add them to your dashboard by using the button below.</div>
        <form @submit.prevent>
          <label for="enterMentorEid" class="form-label">Mentor email</label>
          <div class="row">
            <div class="col-lg-8 mb-2">
              <input type="email" class="form-control mentor-eid" id="enterMentorEid" v-model="email" placeholder="Enter email">
            </div>
            <div class="col-lg-4 mb-2 d-flex justify-content-evenly">
              <button id="add_mentor" @click="addMentor" @click.prevent class="btn btn-success heavy-weight me-2 w-100">Add</button>
              <button id="remove_mentor" @click="removeMentor" @click.prevent class="btn btn-danger heavy-weight w-100">Remove</button>
            </div>
          </div>
        </form>
        <!-- Form feedback
        <div class="text-content feedback text-success bold-weight">"Camron Sikes Added"</div>
        <div class="text-content feedback text-danger bold-weight">"There has been an error please try again"</div>
        -->
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ManageMentors',
  data() {
    return {
      email: ''
    }
  },
  computed: {
    ...mapGetters('users', { findResultsInStore: 'find' }),

    results () {
      return this.findResultsInStore(this.findMentorQuery).data;
    },

    user() {
      return this.$store.state.auth.user;
    },

    findMentorQuery () {
      return {
        query: {
          $select: ['id'],
          preferred_email: this.email
        }
      }
    }
  },
  methods: {
    ...mapActions('users', { findResults: 'find' }),
    findMentor() {
      return this.findResults(this.findMentorQuery);
    },

    addMentor () {
      if (this.results) {
        var mentors = JSON.parse(this.user.mentors)
        mentors = mentors ? mentors : [];

        var mentorId = this.results[0].id;
        if (mentors.indexOf(mentorId) === -1) {
          mentors.push(mentorId)
          this.user.mentors = JSON.stringify(mentors);
          this.user.save();
        }

      }
    },

    removeMentor () {
      if (this.results) {
        var mentors = JSON.parse(this.user.mentors)
        mentors = mentors ? mentors : [];

        var mentorId = this.results[0].id;

        var found = mentors.indexOf(mentorId);
        if (found !== -1) {
          mentors = mentors.filter(function(ele){
            return ele != mentorId;
          });
          this.user.mentors = JSON.stringify(mentors);
          this.user.save();
        }

      }
    }
  }
}
</script>

<style>
/*Add & Remove Mentors*/

.site--body .manage-mentorship .btn {
  font-size: 1.125rem;
}
</style>
