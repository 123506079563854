<!-- 

FYI RENAMED v-model= TO test-model= FOR TESTING LAYOUT 

-->

<template>
  <form class="form-content" @submit.prevent="onSubmit">
  <div class="mentor-profile component">
    <h2>Mentor Profile</h2>
    <div class="content-wrapper">

      <!-- Accepting Mentees -->
      <div class="form-group">
        <div class="group-label">I have completed the mentor training<span class="required-indicator">*</span></div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="completedTrainingChoice1" value=1 name="completedTraining" v-model="user.completed_training" test-model="v$.form.acceptingMentees.$model">
          <label for="completedTrainingChoice1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="completedTrainingChoice2" value=0 name="completedTraining" v-model="user.completed_training" test-model="v$.form.acceptingMentees.$model">
          <label for="completedTrainingChoice2">No</label>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.acceptingMentees.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Full Name -->
      <div class="form-group">
        <label class="group-label" for="fullName">Full Name<span class="required-indicator">*</span></label>
        <input id="fullName" class="form-control" placeholder="Enter first and last name" type="text" v-model="user.name" test-model="v$.form.firstName.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.firstName.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Date of Birth -->
      <div class="form-group">
        <label class="group-label" for="dateOfBirth">Date of Birth<span class="instructions"> (MM/DD/YYYY)</span></label>
        <input id="dateOfBirth" class="form-control" placeholder="Enter dob" type="date" v-model="dob" test-model="v$.form.dateOfBirth.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.dateOfBirth.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Preferred Email -->
      <div class="form-group">
        <label class="group-label" for="preferredEmail">Preferred Email<span class="required-indicator">*</span></label>
        <input id="preferredEmail" class="form-control" placeholder="Enter email" type="email" v-model="user.preferred_email" test-model="v$.form.preferredEmail.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.preferredEmail.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Race/Ethnicity -->
      <div class="form-group">
        <label class="group-label" for="raceEthnicity">Race/Ethnicity<span class="required-indicator">*</span></label>
        <div class="select-wrapper">
          <select id="raceEthnicity" class="form-select form-control" v-model="user.race" test-model="v$.form.raceEthnicity.$model">
            <option default value="">Please select option</option>
            <option>American Indian</option>
            <option>Asian</option>
            <option>Black/African-American</option>
            <option>Hispanic/Latinx</option>
            <option>Native Hawaiian/Pacific Islander</option>
            <option>White/Caucasian</option>
          </select>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.raceEthnicity.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Native Language -->
      <div class="form-group">
        <label class="group-label" for="nativeLanguage">Native Language<span class="required-indicator">*</span></label>
        <div class="select-wrapper">
          <select id="nativeLanguage" class="form-select form-control" v-model="user.native_language" test-model="v$.form.nativeLanguage.$model">
            <option default value="">Please select option</option>
            <option>Arabic</option>
            <option>Chinese</option>
            <option>English</option>
            <option>French</option>
            <option>German</option>
            <option>Portuguese</option>
            <option>Spanish</option>
            <option>Tagalog</option>
            <option>Vietnamese</option>
          </select>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.nativeLanguage.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Gender Identity -->
      <div class="form-group">
        <label class="group-label" for="genderIdentity">Gender Identity<span class="required-indicator">*</span></label>
        <div class="select-wrapper">
          <select id="genderIdentity" class="form-select form-control" v-model="user.gender" test-model="v$.form.genderIdentity.$model">
            <option default value="">Please select option</option>
            <option>Male</option>
            <option>Female</option>
            <option>Trans Male</option>
            <option>Trans Female</option>
            <option>Non-Binary</option>
            <option>Other</option>
          </select>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.genderIdentity.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Sexual Orientation -->
      <div class="form-group">
        <div class="group-label">Sexual Orientation<span class="instructions"> (Check all that apply)</span></div>
        <div class="col-6 col-md-4">
          <div v-for="(option, i) in options['orientation']" :key="i">
            <input class="form-check-input" type="checkbox" :id="'orientation'+i" :value="option"  v-model="orientation">
            <label>{{ option }}</label>
          </div>
        </div>

        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.sexualOrientation.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Preferred Pronouns -->
      <div class="form-group">
        <div class="group-label">Preferred Pronouns<span class="instructions"> (Check all that apply)</span></div>
        <div class="col-6 col-md-3">
          <div v-for="(option, i) in options['pronouns']" :key="i" class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" :id="'pronouns'+i" :value="option" v-model="pronouns">
            <label>{{ option }}</label>
          </div>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.preferredPronouns.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Religion -->
      <div class="form-group">
        <label class="group-label" for="religion">Religion</label>
        <div class="select-wrapper">
          <select id="religion" class="form-select form-control" v-model="user.religion" test-model="v$.form.religion.$model">
            <option default value="">Please select option</option>
            <option>Buddhism</option>
            <option>Catholicism</option>
            <option>Christianity</option>
            <option>Hinduism</option>
            <option>Islam</option>
            <option>Judaism</option>
            <option>Mormon</option>
            <option>Protestant</option>
            <option>Unaffiliated</option>
            <option>Other</option>
          </select>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.religion.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Medical Specialty -->
      <div class="form-group">
        <div class="group-label">Medical Specialty<span class="instructions"> (Check all that apply)</span></div>
        <div v-for="(option, i) in options['specialty']" :key="i" class="col-md-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" :id="'specialty'+i" :value="option" v-model="specialty">
            <label>{{ option }}</label>
          </div>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.medicalSpecialty.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Subspecialty -->
      <div class="form-group">
        <label class="group-label" for="subspecialty">Subspecialty</label>
        <textarea id="subspecialty" class="form-control" placeholder="Enter subspecialty" type="text" rows="5" cols="33" v-model="user.subspecialty" test-model="v$.form.subspecialty.$model"></textarea>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.subspecialty.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Department -->
      <div class="form-group">
        <label class="group-label" for="department">Department, Center, Instititute or Unit</label>
        <div class="select-wrapper">
          <select id="department" class="form-select form-control" v-model="user.department" test-model="v$.form.department.$model">
            <option default value="">Please select option</option>
            <option>Department of Diagnostic Medicine</option>
            <option>Department of Health Social Work</option>
            <option>Department of Internal medicine</option>
            <option>Department of Medical Education</option>
            <option>Department of Neurology</option>
            <option>Department of Neurosurgery</option>
            <option>Department of Oncology</option>
            <option>Department of Ophthamology</option>
            <option>Department of Pediatrics</option>
            <option>Department of Population health</option>
            <option>Department of Psychiatry & Behavorial Sciences</option>
            <option>Department of Surgery and Perioperative Care</option>
            <option>Department of Women's Health</option>
            <option>Center for Health Communication</option>
            <option>Center for Health Interprofessional Practice & Education</option>
            <option>Center for Workplace Health</option>
            <option>Dell Pediatric Research Institute</option>
            <option>Design Institute for Health</option>
            <option>Livestrong Cancer Institutes</option>
            <option>Mitchel & Shannon Wonge Eye Institute</option>
            <option>Mulva Clinic for the Neurosciences</option>
            <option>Value Institute for Health & Care</option>
            <option>Waggoner Center for Alcohol & Addiction</option>
            <option>Office of Research</option>
            <option>Biomedical Data Science Hub</option>
            <option>Health Transformation Research Institute</option>
            <option>Office of Faculty Affairs</option>
            <option>Office of Health Equity</option>
            <option>Office of Diversity, Equity & Inclusion</option>
          </select>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.department.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Academic Rank -->
      <div class="form-group">
        <label class="group-label" for="academicRank">Academic Rank<span class="required-indicator">*</span></label>
        <div class="select-wrapper">
          <select id="academicRank" class="form-select form-control" v-model="user.academic_rank" test-model="v$.form.academicRank.$model">
            <option default value="">Please select option</option>
            <option>Assistant Professor</option>
            <option>Associate Professor</option>
            <option>Professor</option>
          </select>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.academicRank.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Hometown -->
      <div class="form-group">
        <label class="group-label" for="hometown">Hometown (City and State)<span class="required-indicator">*</span></label>
        <input id="hometown" class="form-control" placeholder="Enter hometown" type="text" v-model="user.hometown" test-model="v$.form.hometown.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.hometown.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>s
        -->
      </div>

      <!-- Undergraduate Education -->
      <div class="form-group">
        <label class="group-label" for="undergradEducation">Undergraduate Education (Full Institution Name)<span class="required-indicator">*</span></label>
        <input id="undergradEducation" class="form-control" placeholder="Enter education" type="text" v-model="user.education_undergrad" test-model="v$.form.undergradEducation.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.undergradEducation.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Graduate Education -->
      <div class="form-group">
        <label class="group-label" for="gradEducation">Graduate Education (Full Institution Name)<span class="required-indicator">*</span></label>
        <input id="gradEducation" class="form-control" placeholder="Enter education" type="text" v-model="user.education_graduate" test-model="v$.form.gradEducation.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.gradEducation.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Postgraduate Education -->
      <div class="form-group">
        <label class="group-label" for="postgradEducation">Postgraduate Education (Full Institution Name)</label>
        <input id="postgradEducation" class="form-control" placeholder="Enter education" type="text" v-model="user.education_postgrad" test-model="v$.form.postgradEducation.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.postgradEducation.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Academic Interests -->
      <div class="form-group">
        <label class="group-label" for="academicInterests">Academic Interests<span class="required-indicator">*</span></label>
        <textarea id="academicInterests" class="form-control" placeholder="Enter academic interests" type="text" rows="5" cols="33" v-model="user.interests_academic" test-model="v$.form.academicInterests.$model"></textarea>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.academicInterests.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Outside Interests -->
      <div class="form-group">
        <label class="group-label" for="outsideInterests">Outside Interests<span class="required-indicator">*</span></label>
        <textarea id="outsideInterests" class="form-control" placeholder="Enter outside interests" type="text" rows="5" cols="33" v-model="user.interests_outside" test-model="v$.form.outsideInterests.$model"></textarea>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.outsideInterests.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Preferred Areas of Mentorship -->
      <div class="form-group">
        <div class="group-label">Preferred Areas of Mentorship<span class="required-indicator">*</span></div>
        <div class="col-12">
          <div v-for="(option, i) in options['mentorship_area_preference']" :key="i">
            <input class="form-check-input" type="checkbox" :id="'mentorship_area_preference'+i" :value="option"  v-model="mentorship_area_preference">
            <label>{{ option }}</label>
          </div>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.mentorshipAreas.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Finish the statement *-->
      <div class="form-group">
        <label class="group-label" for="mentorshipStatement">Finish the statement.<span class="required-indicator">*</span></label>
        <input id="mentorshipStatement" class="form-control" placeholder="I am a mentor because..." type="text" v-model="user.mentor_statement" test-model="v$.form.mentorshipStatement.$model">
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.mentorshipStatement.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Preferred Meeting Days -->
      <div class="form-group">
        <div class="group-label">Preferred Meeting Days<span class="required-indicator">*</span></div>
        <div class="col-6 col-md-4">
          <div v-for="(option, i) in options['preferred_dates']" :key="i">
            <input class="form-check-input" type="checkbox" :id="'preferredDates'+i" :value="option"  v-model="preferred_dates">
            <label>{{ option }}</label>
          </div>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.meetingDays.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Preferred Meeting Times -->
      <div class="form-group">
        <div class="group-label">Preferred Meeting Times<span class="required-indicator">*</span></div>
        <div class="col-6 col-md-4">
          <div v-for="(option, i) in options['preferred_times']" :key="i">
            <input class="form-check-input" type="checkbox" :id="'preferredTimes'+i" :value="option"  v-model="preferred_times">
            <label>{{ option }}</label>
          </div>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.meetingDays.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

      <!-- Accepting Mentees -->
      <div class="form-group">
        <div class="group-label">Accepting Mentees<span class="required-indicator">*</span></div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="acceptingMenteesChoice1" value=1 name="acceptingMentees" v-model="user.accepting_mentees" test-model="v$.form.acceptingMentees.$model">
          <label for="acceptingMenteesChoice1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="acceptingMenteesChoice2" value=0 name="acceptingMentees" test-model="v$.form.acceptingMentees.$model">
          <label for="acceptingMenteesChoice2">No</label>
        </div>
        <!-- Error Message
        <div class="input-errors" v-for="(error, index) of v$.form.acceptingMentees.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
        -->
      </div>

    </div>
    <!-- Update Profile -->
    <div class="col-lg-4 mb-2">
      <button class="btn ut-button update-profile heavy-weight p-2 w-100" type="submit">Update Info</button>
    </div>
  </div>
  </form>
</template>

<script>
import moment from 'moment'

export default {
  name: 'MentorProfile',
  data() {
    return {
      initialized: false,
      dob: null,
      orientation: [],
      pronouns: [],
      specialty: [],
      mentorship_area_preference: [],
      preferred_dates: [],
      preferred_times: [],
      options: {
        orientation: [
          "Heterosexual",
          "Lesbian",
          "Gay",
          "Bisexual",
          "Other"
        ],
        pronouns: [
          "He",
          "Him",
          "His",
          "She",
          "Her",
          "Hers",
          "They",
          "Them",
          "Theirs"
        ],
        mentorship_area_preference: [
          "Career advice",
          "Clinical Exposure (i.e. shadowing)",
          "Professional Networking",
          "Research",
          "Work/Life Integration",
          "Writing review (CV, applications, grant proposals, posters, abstracts, journal submissions"
        ],
        specialty: [
          "Allergy and immunology",
          "Anesthesiology",
          "Dermatology",
          "Diagnostic radiology",
          "Emergency medicine",
          "Family medicine",
          "Internal medicine",
          "Medical genetics",
          "Neurology",
          "Nuclear medicine",
          "Obstetrics and gynecology",
          "Ophthalmology",
          "Pathology",
          "Pediatrics",
          "Physical medicine and rehabilitation",
          "Preventive medicine",
          "Psychiatry",
          "Radiation oncology",
          "Surgery",
          "Urology",
          "Not Applicable"
        ],
        preferred_dates: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
          "Any"
        ],
        preferred_times: [
          "Morning",
          "Afternoon",
          "Evening",
          "Any"
        ]
      }
    }
  },
  computed: {
    getAge () {
      return moment.utc().diff(this.user.dob, 'years',false);
    },
    user () {
      var user = this.$store.state.auth.user;
      if (!this.initialized) {
        this.initDOB(user.dob);
        this.initOrientation(user.orientation);
        this.initPronouns(user.pronouns);
        this.initSpecialty(user.specialty);
        this.initAreaPreference(user.mentorship_area_preference);
        this.initPreferredDates(user.preferred_dates);
        this.initPreferredTimes(user.preferred_times);
      }
      return user;
    }
  },
  methods: {
    initDOB(date) {
      if (date) {
        this.dob = moment.utc(date).format('yyyy-MM-DD'); 
      }
      this.initialized=true;
    },
    initOrientation(data) {
      var parsed = JSON.parse(data);
      this.orientation = parsed && parsed.constructor === Array ? parsed : [];
    },
    initPronouns(data) {
      var parsed = JSON.parse(data);
      this.pronouns = parsed && parsed.constructor === Array ? parsed : [];
    },
    initSpecialty(data) {
      var parsed = JSON.parse(data);
      this.specialty = parsed && parsed.constructor === Array ? parsed : [];
    },
    initAreaPreference(data) {
      var parsed = JSON.parse(data);
      this.mentorship_area_preference = parsed && parsed.constructor === Array ? parsed : [];
    },
    initPreferredDates(data) {
      var parsed = JSON.parse(data);
      this.preferred_dates = parsed && parsed.constructor === Array ? parsed : [];
    },
    initPreferredTimes(data) {
      var parsed = JSON.parse(data);
      this.preferred_times = parsed && parsed.constructor === Array ? parsed : [];
    },
    onSubmit () {
      if (this.dob) {
        this.user.dob = moment.utc(this.dob).format('yyyy-MM-DD');
        this.user.age = this.getAge;
      }
      this.user.orientation = JSON.stringify(this.orientation);
      this.user.pronouns = JSON.stringify(this.pronouns);
      this.user.specialty = JSON.stringify(this.specialty);
      this.user.mentorship_area_preference = JSON.stringify(this.mentorship_area_preference);
      this.user.preferred_dates = JSON.stringify(this.preferred_dates);
      this.user.preferred_times = JSON.stringify(this.preferred_times);
      this.user.save()
    }
  }
}
</script>

<style>

</style>
