<template>
<div id="welcomeWrapper">
  <!-- MENTOR WELCOME -->
  <div v-if="user && user.is_mentor == 1" class="component mentor-view">
    <div class="row">
      <div class="col welcome-message">
        <div class="content-block">
          <h2 class="h3-style">Welcome Mentors</h2>
          <div class="text-paragraph">
            <p>Welcome to the Dell Med Connect mentor dashboard! Please fill out your profile below and press the update button to save your changes. The information you place in the profile will be searchable by prospective mentees. We encourage you to complete the mentor training modules located on this page before establishing a formal relationship with a mentee. If you wish to get CME credit please read the <a href="https://utexas.box.com/s/ktf6tymixc6j7vzxht9fzwev2kcu5voj" target="_blank">CME statement</a> before viewing the video modules. Once you have viewed the modules, please indicate so in the form below.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- MENTEE WELCOME -->
  <div v-if="user && user.is_mentor == 0" class="component mentee-view">
    <div class="row">
      <div class="col welcome-message">
        <div class="content-block">
          <h2 class="h3-style">Welcome Mentees</h2>
          <div class="text-paragraph">
            <p class="mb-3">Hello Mentees and welcome to Dell Med Connect, a resource designed to help you find mentorship in self-identified areas of need. Mentors in our program are Dell Med Faculty who have received training on mentorship and who are excited  to make a connection with you! Using the search function on this platform, you can find a mentor based on any characteristic you desire such as  self-identified race, hometown,  outside interests,  or preferred meeting times, to name a few. Once you have found a potential mentor, email them at their preferred email address to make a connection. After you have established a relationship with a mentor, return to this page and add them to your dashboard using the “Add or Remove Mentors” button. Similarly, when the relationship has ended, please remove the mentor from your dashboard and complete an evaluation using the “mentor evaluation survey” link on this page.</p>
            <p>We strongly encourage you to establish relationships with more than one mentor, as one person is unlikely to be able to satisfy all of your mentorship needs.  Also, please take a look at the mentee training videos to learn more about the program and how you can make the most of your mentorship relationships.  Happy searching!</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'WelcomeMessage',
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
/*Welcome Message*/

.site--body .welcome-message .content-block {
  background-color: #FAEEC4;
  border:5px solid #F6E39C;
  border-radius: 1rem;
  padding: 1.75rem;
  margin-bottom: 2.5rem;
}

.site--body .welcome-message .text-paragraph p {
  margin-bottom: 0;
}

.site--body label.training-confirmation {
  font-size: 1.125rem;
  display: inline;
}

@media (min-width: 768px) {
  .site--body label.training-confirmation {
    display: inline-block;
  }
}
</style>
