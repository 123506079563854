<template>
  <div v-if="results.length" class="current-mentorship component">
    <h2>{{ title }}</h2>
    <div class="content-wrapper">
      <div v-for="(result, i) in results" :key="i">
        <div class="content-block">
          <div class="row">
            <div class="col-lg-9">
              <h3>{{ result.name }}</h3>
              <div class="text-link"><a href="mailto:#">{{ result.preferred_email }}</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CurrentMentorship',
  data() {
    return {
      empty: true
    }
  },
  computed: {
    ...mapGetters('users', { findResultsInStore: 'find' }),
    results () {
      var results = [];
      if(JSON.parse(this.user.mentors) || this.user.is_mentor) {
        results = this.findResultsInStore(this.querySearch).data;
      }
      return results;
    },
    user () {
      return this.$store.state.auth.user;
    },
    querySearch () {
      var query = { query: {
        $sort: { email: 1 },
        $limit: 25
      }};

      if (this.user.is_mentor) {
          query.query.mentors = { $regex: this.user.id};
      } else {
        if (JSON.parse(this.user.mentors)) {
          query.query.id = { $in: JSON.parse(this.user.mentors) };
        }
      }
      return query;
    },
    title () {
      if (this.user.is_mentor == 1) {
        return "Current Mentees";
      }
      return "Current Mentors";
    }
  },
  methods: {
    ...mapActions('users', { findResults: 'find' })
  },
  watch: {
    querySearch () {
      if(JSON.parse(this.user.mentors) || this.user.is_mentor) {
        this.findResults(this.querySearch);
        this.empty = false;
      }
    }
  },
  created () {
    if(JSON.parse(this.user.mentors) || this.user.is_mentor) {
       this.findResults(this.querySearch);
      this.empty = false;
    }
  }
}
</script>

<style>
/*Current Mentorship*/

.site--body .small-text {
  font-size: .75rem;
  text-align: right;
}
</style>
