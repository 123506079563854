<template>
  <div id="app">
    <HeaderItem/>
    <router-view/>
    <FooterItem/>
  </div>
</template>

<script>
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import HeaderItem from './components/Header.vue'
import FooterItem from './components/Footer.vue'
import { onMounted, watch } from '@vue/composition-api'


export default {
  name: 'App',
  mounted() {
    document.title = "Dell Medical School | Mentorship Portal";
  },
  components: {
    HeaderItem,
    FooterItem
  },
  setup(props, context) {
    const { $store, $router } = context.root
    // Redirect to homepage if there's a user, otherwise to login page.
    watch(
      () => $store.state.auth.user,
      user => {
        const toRouteName = user ? 'home' : 'login'
        $router.replace({ name: toRouteName })
      },
      { lazy: true }
    )
    // Attempt jwt auth when the app mounts.
    onMounted(() => {
      $store.dispatch('auth/authenticate').catch(error => {
        if (!error.message.includes('Could not find stored JWT')) {
          console.error(error)
        }
      })
    })
    return {}
  }
}
</script>

<style>
/* Global Styles */

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  overflow-x: hidden;
  color: #333f48;
}

h1, h2, h3 {
  font-weight: 700;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.375rem;
}

.h1-style {
  font-size: 2.25rem;
}

.h2-style {
  font-size: 1.75rem;
}

.h3-style {
  font-size: 1.375rem;
}

.heavy-weight {
  font-weight: 700;
}

.bold-weight {
  font-weight: 600;
}

.small-text {
  font-size: .75rem;
}

.ut-button {
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #bf5700;
  border-color: #bf5700;
}

.ut-button:hover {
  color: #ffffff;
  background-color: #a04400;
  border-color: #a04400;
}

.ut-button-secondary {
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #333f48;
  border-color: #333f48;
}

.ut-button-secondary:hover {
  color: #ffffff;
  background-color: #4a5d6a;
  border-color: #4a5d6a;
}

.site--body a {
  color: #bf5700;
  text-decoration: none;
}

.site--body a:hover {
  color: #bf5700;
  text-decoration: underline;
}

/*Form Globals*/

.site--body .form-control {
  font-size: 1rem;
}

.site--body .form-control.mentor-eid {
  font-size: 1.125rem;
}

.site--body .form-group {
  margin-bottom: 1.5rem;
}

.site--body .form-group .group-label {
  font-size: 1.125rem;
  font-weight: 600;
  color: #005F86;
  margin-bottom: .5rem;
}

.site--body .form-select {
  cursor: pointer;
}

.site--body .form-check label {
  font-size: 1rem;
  margin-top: .5rem;
  cursor: pointer;
}

.site--body .form-check {
  margin-bottom: .5rem;
}

.site--body .form-check-input {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  margin-right: .5rem;
}

.form-check-input:checked {
  background-color: #bf5700;
  border-color: #bf5700;
}

/*Dashboard Component Globals*/

.site--body .content-wrapper {
  background-color: #F7F7F7;
  border-radius: 1rem;
  padding: 1.75rem 1.75rem 1.75rem 1.75rem;
  margin-bottom: 2rem;
}

.site--body .content-block + .content-block {
  border-top: 1px solid #C6C9CB;
  padding-top: 1.25rem;
  margin-top: 1.375rem;
}

.site--body .dashboard h1 {
  margin-bottom: 1.75rem;
}

.site--body .component h2 {
  margin-bottom: 1rem;
}

/*Popovers Global*/

.site--body .popover-icon {
  position: relative;
  font-size: .875rem;
  padding-left: .25rem;
  bottom: .8rem;
  line-height: 1rem;
}

.popover-link {
  color: #bf5700;
  text-decoration: none;
}

.popover-link:hover {
  color: #bf5700;
  text-decoration: underline;
}

h3.popover-header {
  background-color: #333f48;
  color: #ffffff;
}

</style>
