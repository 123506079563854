<template>
<header class="site--header">

    <!-- Powered By -->
    <div class="powered-by">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="text-link"><a class="heavy-weight" href="https://dellmed.utexas.edu" target="_blank">POWERED BY DELL MEDICAL SCHOOL</a></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Logo Branding -->
    <div class="logo-branding">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text-header-wrapper">
              <div class="text-header heavy-weight">Dell Med Connect</div>
              <div class="text-subheader">Mentorship Portal</div>
            </div>
          </div>
          <div class="d-none d-lg-block col-lg-6 my-auto"><div class="header-logo h-100"><img class="dms-logo" src="../assets/utdms-white.svg"></div></div>
        </div>
      </div>
    </div>

    <!-- Menu -->
    <div class="menu">
      <div class="container">
        <div class="row">
          <div class="col-md-6 left-menu">
            <ul v-if="user && user.is_mentor == 0" class="list-inline">
              <li class="list-inline-item"><router-link to="/">Dashboard</router-link></li>
              <li class="list-inline-item"><router-link to="/search">Mentor Search</router-link></li>
            </ul>
          </div>
          <div class="col-md-6 right-menu">
            <ul class="list-inline">
              <li class="list-inline-item">
                <form v-if="user && user.is_admin == 1">
                  <div class="">
                    <div class="form-check-inline">View as</div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="adminIsMentorChoice1" value=1 name="isMentor" v-model="isMentor" test-model="v$.form.acceptingMentees.$model">
                      <label for="adminIsMentorChoice1">Mentor</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="adminIsMentorChoice2" value=0 name="isMentor" v-model="isMentor" test-model="v$.form.acceptingMentees.$model">
                      <label for="adminIsMentorChoice2">Mentee</label>
                    </div>
                  </div>
                </form>
              </li>
              <li class="list-inline-item"><a class="bold-weight" href="mailto:help@dellmed.utexas.edu">IT Support</a></li>
              <li class="list-inline-item text-black-50 d-none d-lg-inline">|</li>
              <li v-if="!user" class="list-inline-item"><router-link class="bold-weight" to="/login">Login</router-link></li>
              <li v-if="user" class="list-inline-item"><a @click.prevent="handleLogout" class="bold-weight" href="#">Log Out</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'HeaderItem',
  data() {
    return {
      isMentor: null
    }
  },
  computed: {
    user () {
      var user = this.$store.state.auth.user
      // Temp fix for dob format
      if(user && user.dob) {
        user.dob = "" +moment.utc(user.dob).format('yyyy-MM-DD');
      }
      return user;
    },
    mentor() {
      if (this.user) {
        return this.user.is_mentor;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    handleLogout () {
      /* eslint-disable */
      this.logout()
        .then(_ => {
          this.$router.push('/')
        })
      /* eslint-enable */
    },
  },
  watch: {
    isMentor () {
      if(this.user) {
        this.user.is_mentor = this.isMentor;
        this.user.save();
        this.$router.push('/');
      }
    }
  },
  created () {
    this.isMentor = this.mentor;
  }
}
</script>

<style>
/*Powered by*/

.site--header .powered-by {
  background-color: #333f48;
}

.site--header .powered-by .text-link {
  font-size: .75rem;
  padding-top: .275rem;
  padding-bottom: .275rem;
}

.site--header .powered-by .text-link a {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

/*Logo Branding*/

.site--header .logo-branding {
  background-color: #bf5700;
  border-bottom: 6px solid #F7961F;
}

.site--header .logo-branding .header-logo {
  text-align: right;
}

.site--header .logo-branding .header-logo .dms-logo {
  height: 2.5rem;
}

.site--header .text-header-wrapper {
  padding-top: 1.875rem;
  padding-bottom: 2rem;
}

.site--header .logo-branding .text-header {
  font-size: 1.75rem;
  line-height: 1;
  color: #ffffff;
  padding-bottom: .25rem;
}

@media (min-width: 992px) {
  .site--header .logo-branding .text-header {
    font-size: 3rem;
  }
}

.site--header .logo-branding .text-subheader {
  font-size: 1rem;
  line-height: 1;
  color: #ffffff;
}

@media (min-width: 992px) {
  .site--header .logo-branding .text-subheader {
    font-size: 1.375rem;
  }
}

/*Menu*/

.site--header ul {
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.site--header .menu {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 3rem;
  background-color: #f9fafb;
}
.site--header .menu a {
  color: #333f48;
  padding: .5rem;
  text-decoration: none;
  vertical-align: text-top;
}

.site--header .menu a:hover {
  padding: .5rem;
  background-color: #eeeeee !important;
  text-decoration: none;
  border-radius: 5px;
}

.site--header .menu .router-link-exact-active {
  font-weight: 700;
}

.site--header .menu .right-menu a {
  font-size: .875rem;
  color: #005F86;
}

@media (min-width: 992px) {
  .site--header .menu .right-menu a {
    font-size: 1.125rem;
  }
}

.site--header .menu .right-menu {
  text-align: left;
}

@media (min-width: 768px) {
  .site--header .menu .right-menu {
    text-align: right;
  }
}
</style>
