<!-- 

FYI RENAMED test-model= TO test-model= FOR TESTING LAYOUT 

-->

<template>
  <main class="site--body">

    <!-- Dashboard -->
    <div class="mentee dashboard">
      <div class="container">
        <div class="row">
          <div class="col">

            <!-- Page Title -->
            <h1>Mentor Search</h1>
            <div class="search-helper fs-6 fw-light mb-2">Pro tip: For best results, use more than one search criteria.</div>

            <!-- Dashboard Components -->
            <div class="row mb-5">

              <!-- Column -->
              <div class="col-12">

                <!-- Mentor Search -->
                <form id="mentorSearchForm" class="form-content" @submit.prevent="onSubmit">
                <div class="mentor-search component">
                  <div class="content-wrapper">

                    <div class="row">

                      <!-- Name -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="fullName">Full Name</label>
                          <input id="fullName" class="form-control" placeholder="Enter first and last name" type="text" v-model="form.regex.name" test-model="v$.form.fullName.$model">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.fullName.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Age Range / Age Min and Age Max -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <div class="group-label">Age Range</div>
                          <div class="row">
                            <div class="col-6">
                              <div class="row">
                                <div class="col-xl-6">
                                  <input id="ageMin" class="form-control d-inline-block" type="text" v-model="form.age_min" test-model="v$.form.ageMin.$model">
                                </div>
                                <label class="col-auto age-label" for="ageMin">Min</label>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="row">
                                <div class="col-xl-6">
                                  <input id="ageMax" class="form-control d-inline-block" type="text" v-model="form.age_max" test-model="v$.form.ageMax.$model">
                                </div>
                                <label class="col-auto age-label" for="ageMax">Max</label>
                              </div>
                            </div>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.ageMin.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          <div class="input-errors" v-for="(error, index) of v$.form.ageMax.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Race/Ethnicity -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="raceEthnicity">Race/Ethnicity</label>
                          <div class="select-wrapper">
                            <select id="raceEthnicity" class="form-select form-control" v-model="form.exact.race" test-model="v$.form.raceEthnicity.$model">
                              <option default value="">Please select option</option>
                              <option>American Indian</option>
                              <option>Asian</option>
                              <option>Black/African-American</option>
                              <option>Hispanic/Latinx</option>
                              <option>Native Hawaiian/Pacific Islander</option>
                              <option>White/Caucasian</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.raceEthnicity.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Native Language -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="nativeLanguage">Native Language</label>
                          <div class="select-wrapper">
                            <select id="nativeLanguage" class="form-select form-control" v-model="form.exact.native_language" test-model="v$.form.nativeLanguage.$model">
                              <option default value="">Please select option</option>
                              <option>Arabic</option>
                              <option>Chinese</option>
                              <option>English</option>
                              <option>French</option>
                              <option>German</option>
                              <option>Portuguese</option>
                              <option>Spanish</option>
                              <option>Tagalog</option>
                              <option>Vietnamese</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.nativeLanguage.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Gender Identity -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="genderIdentity">Gender Identity</label>
                          <div class="select-wrapper">
                            <select id="genderIdentity" class="form-select form-control" v-model="form.exact.gender" test-model="v$.form.genderIdentity.$model">
                              <option default value="">Please select option</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Trans Male</option>
                              <option>Trans Female</option>
                              <option>Non-Binary</option>
                              <option>Other</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.genderIdentity.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Sexual Orientation -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="sexualOrientation">Sexual Orientation</label>
                          <div class="select-wrapper">
                            <select id="sexualOrientation" class="form-select form-control" v-model="form.regex.orientation" test-model="v$.form.sexualOrientation.$model">
                              <option default value="">Please select option</option>
                              <option>Heterosexual</option>
                              <option>Lesbian</option>
                              <option>Gay</option>
                              <option>Bisexual</option>
                              <option>Other</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.sexualOrientation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Pronouns -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="pronouns">Preferred Pronouns</label>
                          <div class="select-wrapper">
                            <select id="pronouns" class="form-select form-control" v-model="form.regex.pronouns" test-model="v$.form.pronouns.$model">
                              <option default value="">Please select option</option>
                              <option>He</option>
                              <option>Him</option>
                              <option>His</option>
                              <option>She</option>
                              <option>Her</option>
                              <option>Hers</option>
                              <option>They</option>
                              <option>Them</option>
                              <option>Theirs</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.sexualOrientation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Religion -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="religion">Religion</label>
                          <div class="select-wrapper">
                            <select id="religion" class="form-select form-control" v-model="form.exact.religion" test-model="v$.form.religion.$model">
                              <option default value="">Please select option</option>
                              <option>Buddhism</option>
                              <option>Catholicism</option>
                              <option>Christianity</option>
                              <option>Hinduism</option>
                              <option>Islam</option>
                              <option>Judaism</option>
                              <option>Mormon</option>
                              <option>Protestant</option>
                              <option>Unaffiliated</option>
                              <option>Other</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.religion.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Medical Specialty -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="medicalSpecialty">Medical Specialty</label>
                          <div class="select-wrapper">
                            <select id="medicalSpecialty" class="form-select form-control" v-model="form.regex.specialty" test-model="v$.form.medicalSpecialty.$model">
                              <option default value="">Please select option</option>
                              <option>Allergy and immunology</option>
                              <option>Anesthesiology</option>
                              <option>Dermatology</option>
                              <option>Diagnostic radiology</option>
                              <option>Emergency medicine</option>
                              <option>Family medicine</option>
                              <option>Internal medicine</option>
                              <option>Medical genetics</option>
                              <option>Neurology</option>
                              <option>Nuclear medicine</option>
                              <option>Obstetrics and gynecology</option>
                              <option>Ophthalmology</option>
                              <option>Pathology</option>
                              <option>Pediatrics</option>
                              <option>Physical medicine and rehabilitation</option>
                              <option>Preventive medicine</option>
                              <option>Psychiatry</option>
                              <option>Radiation oncology</option>
                              <option>Surgery</option>
                              <option>Urology</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.medicalSpecialty.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Subspecialty -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="subspecialty">Subspecialty</label>
                          <input id="subspecialty" class="form-control" placeholder="Enter subspecialty" type="text" v-model="form.regex.subspecialty">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.fullName.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Department -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="department">Department</label>
                          <input id="department" class="form-control" placeholder="Enter department" type="text" v-model="form.regex.department">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.fullName.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Academic Rank -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="academicRank">Academic Rank</label>
                          <div class="select-wrapper">
                            <select id="academicRank" class="form-select form-control" v-model="form.exact.academic_rank" test-model="v$.form.medicalSpecialty.$model">
                              <option default value="">Please select option</option>
                              <option>Assistant professor</option>
                              <option>Associate professor</option>
                              <option>Professor</option>
                            </select>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.medicalSpecialty.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Hometown -->
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="group-label" for="hometown">Hometown</label>
                          <input id="hometown" class="form-control" placeholder="Enter hometown" type="text" v-model="form.regex.hometown">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.fullName.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                    </div>
                    <div class="row">

                      <!-- Undergraduate Education -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="group-label" for="undergradEducation">Undergraduate Education</label>
                          <input id="undergradEducation" class="form-control" placeholder="Enter institution name" type="text" v-model="form.regex.education_undergrad" test-model="v$.form.undergradEducation.$model">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.undergradEducation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Graduate Education -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="group-label" for="gradEducation">Graduate Education</label>
                          <input id="gradEducation" class="form-control" placeholder="Enter institution name" type="text" v-model="form.regex.education_graduate" test-model="v$.form.gradEducation.$model">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.gradEducation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                    </div>
                    <div class="row">

                      <!-- Postgraduate Education -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="group-label" for="postgradEducation">Postgraduate Education</label>
                          <input id="postgradEducation" class="form-control" placeholder="Enter institution name" type="text" v-model="form.regex.education_postgrad" test-model="v$.form.postgradEducation.$model">
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.postgradEducation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                    </div>
                    <div class="row">

                      <!-- Academic Interests -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="group-label" for="academicInterests">Academic Interests</label>
                          <textarea id="academicInterests" class="form-control" placeholder="Enter academic interests" type="text" rows="5" cols="33" v-model="form.regex.interests_academic" test-model="v$.form.academicInterests.$model"></textarea>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.academicInterests.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Outside Interests -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="group-label" for="outsideInterests">Outside Interests</label>
                          <textarea id="outsideInterests" class="form-control" placeholder="Enter outside interests" type="text" rows="5" cols="33" v-model="form.regex.interests_outside" test-model="v$.form.outsideInterests.$model"></textarea>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.outsideInterests.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                    </div>

                    <!-- Preferred Areas of Mentorship -->
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="group-label">Preferred Areas of Mentorship</div>
                          <div class="row">
                            <div v-for="(option, i) in options['mentorship_area_preference']" :key="i">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" name="preferredAreas" type="checkbox" :id="'mentorship_area_preference'+i" :value="option"  v-model="form.mentorship_area_preference">
                                <label>{{ option }}</label>
                              </div>
                            </div>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.mentorshipAreas.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>
                    </div>

                    <!-- Preferred Days -->
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <div class="group-label">Preferred Meeting Days</div>
                          <div class="row">
                            <div v-for="(option, i) in options['preferred_dates']" :key="i">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" name="preferredDays" type="checkbox" :id="'preferred_date_'+i" :value="option"  v-model="form.preferred_dates">
                                <label>{{ option }}</label>
                              </div>
                            </div>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.mentorshipAreas.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>

                      <!-- Preferred Times -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <div class="group-label">Preferred Meeting Times</div>
                          <div class="row">
                            <div v-for="(option, i) in options['preferred_times']" :key="i">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" name="preferredTimes" type="checkbox" :id="'preferred_time_'+i" :value="option"  v-model="form.preferred_times">
                                <label>{{ option }}</label>
                              </div>
                            </div>
                          </div>
                          <!-- Error Message
                          <div class="input-errors" v-for="(error, index) of v$.form.mentorshipAreas.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                          -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Search Button -->
                <div class="row justify-content-md-center  mb-4">
                  <div class="col-lg-3">
                    <button id="search_mentors" class="btn ut-button search-button heavy-weight p-2 w-100" type="submit">Search Mentors</button>
                  </div>
                </div>
                </form>

                <!-- Search Results -->
                <div class="search-results component" v-if="searchComplete">
                  <h2>Search Results <span class="fs-6 fw-light mb-2">(First 25 results shown)</span></h2>

                  <!-- Search feedback -->
                    <div class="text-content feedback text-success bold-weight mb-2">{{ results.length }} match(s) based on search criteria</div>
                    <!-- <div class="text-content feedback text-danger bold-weight mb-2">"There are no matches, please try refining your search criteria."</div> -->
                  <SearchResult
                    v-for="user in results"
                    :key="user.id"
                    :user="user"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>   
</template>

<script>
//import { useFind } from 'feathers-vuex'
//import { computed } from '@vue/composition-api'
import SearchResult from '../components/SearchResult.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SearchView',
  components: {
    SearchResult
  },
  data() {
    return {
      searchComplete: false,
      form: {
        exact: {
          race: '',
          native_language: '',
          gender: '',
          academic_rank: '',
          religion: ''
        },
        regex: {
          name: '',
          education_undergrad: '',
          education_graduate: '',
          education_postgrad: '',
          age_min: 0,
          age_max: 99,
          orientation: '',
          pronouns: '',
          interests_academic: '',
          interests_outside: '',
          specialty: '',
          subspecialty: '',
          department: '',
          hometown: ''
        },
        mentorship_area_preference: [],
        preferred_dates: [],
        preferred_times: []
      },
      options: {
        mentorship_area_preference: [
          "Career advice",
          "Clinical Exposure (i.e. shadowing)",
          "Professional Networking",
          "Research",
          "Work/Life Integration",
          "Writing review (CV, applications, grant proposals, posters, abstracts, journal submissions)"
        ],
        preferred_dates: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
          "Any"
        ],
        academic_rank: [
          "Assistant professor",
          "Associate professor",
          "Professor"
        ],
        preferred_times: [
          "Morning",
          "Afternoon",
          "Evening",
          "Any"
        ]
      },
      results: []
    }
  },
  computed: {
    ...mapGetters('users', { findResultsInStore: 'find' }),
    querySearch () {
      var query = {
        $sort: { name: 1 },
        $limit: 25,
        is_mentor: 1
      };

      for(const field in this.form.regex) {
        if (this.form.regex[field].length) {
          query[field] = {$regex: this.form.regex[field]};
        }
      }

      for(const field in this.form.exact) {
        if (this.form.exact[field].length) {
          query[field] = this.form.exact[field];
        }
      }

      if (this.form.mentorship_area_preference != []
        || this.form.mentorship_area_preference != []
        || this.form.mentorship_area_preference != []
      ) {
        query['$and'] = []

        // Area preference
        for (const area in this.form.mentorship_area_preference) {
          query['$and'].push({
            mentorship_area_preference: { $regex: this.form.mentorship_area_preference[area].replace(/(?=[() ])/g, "\\") }
          });
        }

        // Preferred Dates
        for (const date in this.form.preferred_dates) {
          query['$and'].push({
            preferred_dates: { $regex: this.form.preferred_dates[date].replace(/(?=[() ])/g, "\\") }
          });
        }

        // Preferred Times
        for (const time in this.form.preferred_times) {
          query['$and'].push({
            preferred_times: { $regex: this.form.preferred_times[time].replace(/(?=[() ])/g, "\\") }
          });
        }
      }

      return { query: query};
    }
  },
  methods: {
    ...mapActions('users', { findResults: "find" }),
    onSubmit() {
      this.findResults(this.querySearch).then((results) =>{
        this.results = results["data"];
        this.searchComplete = true;
      });
    }
  }
}
</script>

<style>
/*Mentor Search*/

@media (min-width: 1200px) {
  .site--body .mentor-search .age-label {
    padding: 0;
  }
}

.site--body .search-results .faculty-name {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.site--body .search-results .title {
  font-size: 1.25rem;
  font-weight: 700;
}

.site--body .search-results .description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .site--body .search-results .right-column {
    padding-left: 2rem;
    border-left: 1px solid #C6C9CB;
  }
}
</style>
