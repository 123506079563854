<template>
  <main class="login container">
    <div class="row">
      <div class="col-lg-6 mx-auto mb-3">
        <h1>Login</h1>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-6 mx-auto">
        <div v-if="error" class="error">
          {{ error.message }}
          <a class="close" href="javascript://" @click.prevent="dismissError">dismiss</a>
        </div>
        <form class="form" method="post" @submit.prevent="onSubmit(email, password)">
          <div class="mb-3">
            <label for="emailLogin" class="form-label">Email address</label>
            <input
                id="emailLogin"
                v-model="email"
                class="form-control"
                type="email"
                name="email"
              />
          </div>
          <div class="mb-3">
            <label for="passwordLogin" class="form-label">Password</label>
            <input id="passwordLogin" v-model="password" class="form-control" type="password" name="password"/>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-2">
              <button type="submit" class="btn ut-button update-profile heavy-weight p-2 w-100">Login</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12 col-6-tablet push-3-tablet text-center">
        <h1 class="font-100">Welcome Back</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-6-tablet push-3-tablet col-4-desktop push-4-desktop">
        <div v-if="error" class="error">
          {{ error.message }}
          <a class="close" href="javascript://" @click.prevent="dismissError">dismiss</a>
        </div>

        <form class="form" method="post" @submit.prevent="onSubmit(email, password)">
          <fieldset>
            <input
              v-model="email"
              class="block"
              type="email"
              name="email"
              placeholder="email"
            />
          </fieldset>

          <fieldset>
            <input v-model="password" class="block" type="password" name="password" placeholder="password" />
          </fieldset>

          <button type="submit" class="button button-primary block login">
            Login
          </button>

          <router-link as="button" :to="{ name: 'HomeView' }" class="button button-secondary block">Back</router-link>
        </form> 
      </div>
    </div> -->
  </main>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  name: 'LoginView',
  setup(props, context) {
    const { $store } = context.root
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    function dismissError() {
      error.value = null
    }
    function onSubmit(email, password) {
      $store
        .dispatch('auth/authenticate', { strategy: 'local', email, password })
        // Just use the returned error instead of mapping it from the store.
        .catch(err => {
          // Convert the error to a plain object and add a message.
          let type = err.className
          err = Object.assign({}, err)
          err.message =
            type === 'not-authenticated'
              ? 'Incorrect email or password.'
              : 'An error prevented login.'
          this.error = err
        })
    }
    return {
      email,
      password,
      error,
      dismissError,
      onSubmit
    }
  }
}
</script>
