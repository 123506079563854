<template>
<div id="resourceWrapper">
  <!-- MENTOR VIEW -->  
  <div v-if="user && user.is_mentor == 1" class="mentorship-resources component mentor-view">
    <h2 class="d-inline-block">Mentor Resources</h2>

    <!-- Popover for Mentors -->
    <div id="mentor-info-popover" class="popover-icon d-inline-block">
      <a tabindex="0" class="popover-tip" role="button" data-bs-toggle="popover" data-bs-trigger="focus" title="Additional Information" data-bs-placement="right"><i class="bi bi-info-circle"></i></a>
    </div>
    <div id="mentor-info-title" class='d-none description-pop-over'>Additional Information</div>
    <div id="mentor-info-content" class='d-none description-pop-over'>
      <p>The following resources have been provided to help enhance your mentorship relationships. Please watch the training modules and then update your mentor profile to confirm you have completed your training. If you wish to get CME credit from watching the training modules, you will need to use the activity code and direct link to CME Tracker provided at the end of each video. When accessing CME Tracker you must first register or login before you can submit your activity code to get credit. If you have any questions or issues please <a class="popover-link" href="mailto:dellmedconnect@austin.utexas.edu">contact us.</a></p>
    </div>

    <div class="content-wrapper">
      <div class="content-block">
        <h3>Training Modules</h3>
        <ul>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#mentorModalWindow1">An Introduction To The Program</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#mentorModalWindow1"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#mentorModalWindow2">Racial Diversity in the Physician Workforce: Why It Matters</a>
            <!-- <span class="video-length d-none d-md-inline-block">(10min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#mentorModalWindow2"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#mentorModalWindow3">Unique Challenges Facing Underrepresented Minorities in Medicine</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#mentorModalWindow3"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#mentorModalWindow4">How to Be an Effective Mentor</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#mentorModalWindow4"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#mentorModalWindow5">Moving from Mentorship to Sponsorship</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#mentorModalWindow5"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#mentorModalWindow6">How to Receive Formal Recognition Toward Mentorship Promotion Criterion</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#mentorModalWindow6"><i class="bi bi-play-circle"></i></a>
          </li>
        </ul>
      </div>
      <div class="content-block">
        <h3>Have Questions or Feedback?</h3>
        <div class="text-content">Please email us with any questions or feedback at <a href="mailto:dellmedconnect@austin.utexas.edu">dellmedconnect@austin.utexas.edu</a>
        </div>
      </div>
    </div>

    <!-- Modal Windows -->
    <!-- Mentor Modal Window 1 -->
    <div class="modal fade" id="mentorModalWindow1" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/703808945?h=f972f0d561&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentor Module 1"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentor Modal Window 2 -->
    <div class="modal fade" id="mentorModalWindow2" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/703858081?h=c2bf152ecb&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentor Module 2"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentor Modal Window 3 -->
    <div class="modal fade" id="mentorModalWindow3" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/704686512?h=d29dc47377&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentor Module 3"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentor Modal Window 4 -->
    <div class="modal fade" id="mentorModalWindow4" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/704689155?h=5d56127007&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentor Module 4"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentor Modal Window 5 -->
    <div class="modal fade" id="mentorModalWindow5" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/705359213?h=2290a6f4c9&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentor Module 5"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentor Modal Window 6 -->
    <div class="modal fade" id="mentorModalWindow6" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/705361585?h=1db88e62ad&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentor Module 6"></iframe></div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <!-- MENTEE VIEW -->
  <div v-if="user && user.is_mentor == 0" class="mentorship-resources component mentee-view">
    <h2 class="d-inline-block">Mentee Resources</h2>

    <!-- Popover for Mentees -->
    <div id="mentee-info-popover" class="popover-icon d-inline-block">
      <a tabindex="0" class="popover-tip" role="button" data-bs-toggle="popover" data-bs-trigger="focus" title="Additional Information" data-bs-placement="right"><i class="bi bi-info-circle"></i></a>
    </div>
    <div id="mentee-info-title" class='d-none description-pop-over'>Additional Information</div>
    <div id="mentee-info-content" class='d-none description-pop-over'>
      <p>The following resources have been provided to help enhance your mentorship relationships. Please watch the training modules and fillout the mentor evaluation survey when your mentor relationship has ended. If you have any questions or issues please <a class="popover-link" href="mailto:dellmedconnect@austin.utexas.edu">contact us.</a></p>
    </div>

    <div class="content-wrapper">
      <div class="content-block">
        <h3>Training Modules</h3>
        <ul>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#menteeModalWindow1">An Introduction to the Program</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#menteeModalWindow1"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#menteeModalWindow2">How to Get the Most From Your Mentorship Relationships</a>
            <!-- <span class="video-length d-none d-md-inline-block">(10min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#menteeModalWindow2"><i class="bi bi-play-circle"></i></a>
          </li>
          <li class="text-content video-module d-flex align-items-baseline">
            <a class="d-inline-block pe-1" href="#" data-bs-toggle="modal" data-bs-target="#menteeModalWindow3">Developing an Individualized Learning Plan</a>
            <!-- <span class="video-length d-none d-md-inline-block">(5min)</span> -->
            <a href="#" class="ms-auto" role="button" aria-label="Play" data-bs-toggle="modal" data-bs-target="#menteeModalWindow3"><i class="bi bi-play-circle"></i></a>
          </li>
        </ul>
      </div>
      <div class="content-block">
        <h3>Mentor Evaluation Survey</h3>
        <div class="text-content"><a href="https://utexas.qualtrics.com/jfe/form/SV_9ntL05W1rEH0wbc" target="_blank">Evaluation Survey Link</a></div>
      </div>
      <div class="content-block">
        <h3>Have Questions or Feedback?</h3>
        <div class="text-content">Please email us with any questions or feedback at <a href="mailto:dellmedconnect@austin.utexas.edu">dellmedconnect@austin.utexas.edu</a>
        </div>
      </div>
    </div>

    <!-- Modal Windows -->
    <!-- Mentee Modal Window 1 -->
    <div class="modal fade" id="menteeModalWindow1" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/703799028?h=12111266f3&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentee Module 1"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentee Modal Window 2 -->
    <div class="modal fade" id="menteeModalWindow2" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/703800667?h=41a0786dc7&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentee Module 2"></iframe></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mentee Modal Window 3 -->
    <div class="modal fade" id="menteeModalWindow3" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalWindowTitle" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalWindowTitle">Training Module</h5>
            <button @click="stopVideos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/703805797?h=f5261b6ced&;badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mentee Module 3"></iframe></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>

const bootstrap = require('bootstrap')

export default {
  name: 'MentorshipResources',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted() {
    if(this.user && this.user.is_mentor == 0) {
      // eslint-disable-next-line no-unused-vars
      const menteeInfoPopover = new bootstrap.Popover(document.getElementById('mentee-info-popover'), {
        html: true,
        trigger: 'focus',
        title: function() {
          const popTitle = document.getElementById('mentee-info-title').innerHTML;
          return popTitle;
        },
        content: function() {
          const popContent = document.getElementById('mentee-info-content').innerHTML;
          return popContent;
        }
      })
    } else if (this.user && this.user.is_mentor == 1) {
      // eslint-disable-next-line no-unused-vars
      const mentorInfoPopover = new bootstrap.Popover(document.getElementById('mentor-info-popover'), {
        html: true,
        trigger: 'focus',
        title: function() {
          const popTitle = document.getElementById('mentor-info-title').innerHTML;
          return popTitle;
        },
        content: function() {
          const popContent = document.getElementById('mentor-info-content').innerHTML;
          return popContent;
        }
      })
    }
  },
  methods: {
    stopVideos() {
      // TODO refine this selector once we know more about the iframes
      var vimeoIframes = document.getElementsByTagName("iframe");
      for (var i = 0; i < vimeoIframes.length; ++i) {
        vimeoIframes[i].contentWindow.postMessage('{"method":"pause"}', "*");
      }
    }
  }
}



</script>

<style>
/*Mentorship Resources*/

.site--body .video-length {
  font-size: .75rem;
}

.site--body .mentorship-resources ul {
  list-style: none;
  padding-left: 0;
}

.site--body .mentorship-resources .video-module .bi-play-circle {
  font-size: 1.25rem;
}
</style>
