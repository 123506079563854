<template>
  <div v-if="user">
    <div v-if="user.first_login" id="role-wrapper">
      <RoleItem/>
    </div>
    <div v-else-if="user.is_mentor!=1" id="mentee-wrapper">
      <MenteeItem/>
    </div>
    <div v-else>
      <MentorItem/>
    </div>
  </div>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import MenteeItem from '../components/Mentee.vue'
import MentorItem from '../components/Mentor.vue'
import RoleItem from '../components/Role.vue'

export default {
  name: 'HomeView',
  components: {
    MenteeItem,
    MentorItem,
    RoleItem
  },
  setup(props, context) {
    const { User } = context.root.$FeathersVuex.api
    const { $store } = context.root

    const user = computed(() => $store.state.auth.user)
    const usersParams = computed(() => {
      return {
        query: {
          $sort: { email: 1 },
          $limit: 25
        }
      }
    })
    const { items: users } = useFind({ model: User, params: usersParams })

    function logout() {
      return $store.dispatch('auth/logout');
    }

    return {
      user,
      users,
      logout
    }
  }
}
  
</script>

<style>
</style>
